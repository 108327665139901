import { useEffect } from "react";
import { IContext } from "..";

//Takes in id and returns if the option is true or false

const useOption = (context: IContext | null, id: string) => {
  //On reset state change run this
  useEffect(() => {
    //Get formData context
    if (context) {
      context.setFormData((p) => ({
        ...p,
        [id]: false,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context?.reset]);

  // Context formData - get and check if this one is checked
  let isChecked = false;
  if (context) {
    isChecked = context.formData[id] || isChecked;
  }
  return isChecked;
};

export default useOption;
