import React from "react";
import styled from "styled-components";

const ProdName = styled.b`
  display: block;
  font-size: 1.1rem;
  margin-bottom: 0.3rem;
`;

interface INameProps {
  children: React.ReactNode;
}

const Name = ({ children }: INameProps) => {
  return <ProdName>{children}</ProdName>;
};

export default Name;
