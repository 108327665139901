import React from "react";
import styled from "styled-components";
import Divider from "../../../../Divider/Divider";

//Styles
const ProdName = styled.b`
  display: block;
  font-size: xx-large;
`;

interface INameProps {
  children: React.ReactNode;
}
const Name = ({ children }: INameProps) => {
  return (
    <>
      <ProdName>{children}</ProdName>
      <Divider />
    </>
  );
};

export default Name;
