import React from "react";
import { useContext } from "react";

import { HostVansDetailContext } from "../VansDetail";

const Photos = () => {
  const context = useContext(HostVansDetailContext);
  console.log(context);

  return (
    <p>
      <img src={context?.imageUrl} alt="van" style={{ width: "100px" }} />
    </p>
  );
};

export default Photos;
