import { NavLink, NavLinkProps } from "react-router-dom";
import "./BasicLinkStyle.css";

import { useIsActive } from "../custom-functions";

const styles = {
  textDecoration: "none",
  color: "inherit",
};

const BasicLink = ({ to, className, children, ...rest }: NavLinkProps) => {
  return (
    <NavLink to={to} style={styles} className={useIsActive} {...rest}>
      {children}
    </NavLink>
  );
};

export default BasicLink;
