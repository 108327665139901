import React, { useContext } from "react";
import styled from "styled-components";

//Subcomponent
import Image from "./Image";
import Info from "./Info";

//Import context
import { ProductDetailContext } from "..";

//Style
const MainWrap = styled.main<{ $width: number }>`
  display: flex;
  gap: 3rem;
  flex-direction: ${({ $width }) => ($width < 900 ? "column" : "row")};
`;

interface IMainProps {
  children: React.ReactNode;
}
const Main = ({ children }: IMainProps) => {
  const { width } = useContext(ProductDetailContext);
  return <MainWrap $width={width}>{children}</MainWrap>;
};

Main.Image = Image;
Main.Info = Info;

export default Main;
