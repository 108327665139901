import React from "react";
import "./index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

//pages
import Mainpage from "./router/pages/Mainpage";
import About from "./router/pages/About";
import Penises from "./router/pages/Penises";
import Detail from "./router/pages/Detail";
import Income from "./router/pages/host/Income";
import Dashboard from "./router/pages/host/Dashboard";
import Reviews from "./router/pages/host/Reviews";
import Vans from "./router/pages/host/Vans";
import VansDetail from "./router/pages/host/VansDetail";
import Details from "./router/pages/host/_detail/Details";
import Photos from "./router/pages/host/_detail/Photos";
import Pricing from "./router/pages/host/_detail/Pricing";

//Layouts
import MenuAndFooter from "./router/layouts/MenuAndFooter";
import HostNav from "./router/layouts/HostNav";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MenuAndFooter />}>
          <Route index element={<Mainpage />} />
          <Route path="about" element={<About />} />

          <Route path="penises">
            <Route index element={<Penises />} />
            <Route path=":id" element={<Detail />} />
          </Route>

          <Route path="host" element={<HostNav />}>
            <Route index element={<Dashboard />} />
            <Route path="income" element={<Income />} />
            <Route path="reviews" element={<Reviews />} />
            <Route path="vans">
              <Route index element={<Vans />} />
              <Route path=":id" element={<VansDetail />}>
                <Route index element={<Details />} />
                <Route path="pricing" element={<Pricing />} />
                <Route path="photos" element={<Photos />} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
