import React from "react";
import styled from "styled-components";

//Subcomponents
import Image from "./Image";
import TextContent from "./TextContent";

//Styled
const ProductItem = styled.div`
  background-color: #f7eed5;
  padding: 27px;
  border-radius: 5px;
  border: 2px solid #d2d2d2;
  @media (max-width: 768px) {
    text-align: center;
  }
`;

interface IHostProductDetailProps {
  children: React.ReactNode;
}

const HostProductDetail = ({ children }: IHostProductDetailProps) => {
  return <ProductItem>{children}</ProductItem>;
};

HostProductDetail.Image = Image;
HostProductDetail.TextContent = TextContent;
export default HostProductDetail;
