import React, { useContext } from "react";
import styled from "styled-components";

//Import context
import { FiltersContext } from "..";

interface IClearProps {
  children: React.ReactNode;
}

const ClearButton = styled.button`
  display: inline-block;
  color: black;
  text-decoration: underline;
  padding: 1rem;
  width: max-content;
  margin-left: 1.3rem;
  cursor: pointer;
  border: none;
  background: none;
  font-weight: bold;
`;

const Clear = ({ children }: IClearProps) => {
  const context = useContext(FiltersContext);
  const handleClear = (e: React.MouseEvent) => {
    e.preventDefault();
    context?.setSearchParams();
  };
  return <ClearButton onClick={handleClear}>{children}</ClearButton>;
};

export default Clear;
