import React from "react";

//Subcomponents
import TimePer from "./TimePer";
import Value from "./Value";

export interface IPriceProps {
  children: React.ReactNode;
}

const Price = ({ children }: IPriceProps) => {
  return <div>{children}</div>;
};

Price.TimePer = TimePer;
Price.Value = Value;

export default Price;
