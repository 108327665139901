import React, { useRef, createContext } from "react";
import styled from "styled-components";

//Custom hooks
import useWatchWidth from "./hooks/useWatchWidth";

//Subcomponents
import GoBack from "./GoBack";
import Main from "./Main";

//Context export
interface IProductDetailContext {
  width: number;
}
export const ProductDetailContext = createContext<IProductDetailContext>({
  width: 1000,
});

//Styles
const ProductDetailWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

interface IProductDetailProps {
  children: React.ReactNode;
}
const ProductDetail = ({ children }: IProductDetailProps) => {
  const wrapRef = useRef<HTMLDivElement>(null);
  const wrapWidth = useWatchWidth(wrapRef);

  return (
    <ProductDetailContext.Provider value={{ width: wrapWidth }}>
      <ProductDetailWrap ref={wrapRef}>{children}</ProductDetailWrap>
    </ProductDetailContext.Provider>
  );
};

ProductDetail.GoBack = GoBack;
ProductDetail.Main = Main;

export default ProductDetail;
