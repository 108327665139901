import React from "react";
import styled from "styled-components";

interface IFooterProps {
  children: React.ReactNode;
}

const FOOTER = styled.footer`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px; /* Footer height - the same needs to go ContentWrap padding-bottom!!! */
  background-color: #252525;
  color: #aaaaaa;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Footer = ({ children }: IFooterProps) => {
  return <FOOTER>{children}</FOOTER>;
};

export default Footer;
