import React, { ComponentPropsWithoutRef } from "react";
import styled from "styled-components";

//Child components
import Heading from "./Heading";
import Text from "./Text";

interface ITextSectionProps extends ComponentPropsWithoutRef<"main"> {
  children: React.ReactNode;
  color?: string;
  bgColor?: string;
}

const Main = styled.main<{ $bgColor: string; $color: string }>`
  background-color: ${({ $bgColor }) => $bgColor};
  color: ${({ $color }) => $color};
`;

const TextSection = ({
  children,
  color = "auto",
  bgColor = "auto",
  ...rest
}: ITextSectionProps) => {
  return (
    <Main $bgColor={bgColor} $color={color} {...rest}>
      {children}
    </Main>
  );
};

TextSection.Heading = Heading;
TextSection.Text = Text;

export default TextSection;
