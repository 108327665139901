import React from "react";
import styled from "styled-components";

//Styled
const HostProdImg = styled.img`
  height: 100%;
  margin-right: 15px;
  border-radius: 10px;
  box-shadow: 3px 3px 10px grey;
`;

interface IImage {
  source: string;
}
const Image = ({ source }: IImage) => {
  return <HostProdImg src={source} />;
};

export default Image;
