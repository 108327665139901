import React from "react";
import styled from "styled-components";

interface IContentWrapProps {
  children: React.ReactNode;
}

//Footer always needs the same height as padding-bottom!!!
const CONTENTWRAP = styled.div`
  padding-bottom: 50px;
`;

const ContentWrap = ({ children }: IContentWrapProps) => {
  return <CONTENTWRAP>{children}</CONTENTWRAP>;
};

export default ContentWrap;
