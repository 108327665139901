//Libs
import React from "react";
import styled from "styled-components";

//Import styled
import BasicLink from "../../../router/helpers/custom-components/BasicNavLink";

const Header = styled.header`
  height: 112px;
  background-color: #fff7ed;
  color: black;
  display: flex;
  align-items: center;
  padding: 0 30px;
  border: 1px solid black;
`;

const Navigation = styled.nav`
  margin-left: auto;
  text-align: right;
`;

const NavMenu = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const NavMenuItem = styled.li`
  display: inline-block;
  margin: 0 1em;
`;

interface IMenuProps {
  active?: string;
}
const Menu = ({ active }: IMenuProps) => {
  return (
    <Header>
      <h1>
        <BasicLink to="/">#VANLIFE</BasicLink>
      </h1>
      <Navigation>
        <NavMenu>
          <NavMenuItem>
            <BasicLink to="/host">Host</BasicLink>
          </NavMenuItem>
          <NavMenuItem>
            <BasicLink to="/about">About</BasicLink>
          </NavMenuItem>
          <NavMenuItem>
            <BasicLink to="/penises">Penises</BasicLink>
          </NavMenuItem>
        </NavMenu>
      </Navigation>
    </Header>
  );
};

export default Menu;
