import React from "react";
import styled from "styled-components";

interface IImageProps {
  source: string;
}

const Img = styled.img`
  width: 100%;
`;

const Image = ({ source }: IImageProps) => {
  return <Img src={source} alt="van" />;
};

export default Image;
