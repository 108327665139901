import React from "react";
import {
  default as ListingTag,
  ITagProps,
} from "../../../../Products/Product/Info/Basic/Tag";
import Divider from "../../../../Divider/Divider";

const Tag = ({ children }: ITagProps) => {
  return (
    <>
      <ListingTag style={{ width: "max-content" }}>{children}</ListingTag>
      <Divider />
    </>
  );
};

export default Tag;
