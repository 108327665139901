import React from "react";

//Subcomponents
import Name from "./Name";
import Tag from "./Tag";

interface IBasicProps {
  children: React.ReactNode;
}

const Basic = ({ children }: IBasicProps) => {
  return <div>{children}</div>;
};

Basic.Name = Name;
Basic.Tag = Tag;

export default Basic;
