import React from "react";
import { useContext } from "react";

import { HostVansDetailContext } from "../VansDetail";

const Pricing = () => {
  const context = useContext(HostVansDetailContext);
  console.log(context);

  return <p>{context?.price} $ / Day</p>;
};

export default Pricing;
