import React, { useEffect, useState, useCallback } from "react";
import "../../MockServer";

//Components
import Heading from "../../components/ContentWrap/TextSection/Heading";
import Divider from "../../components/ContentWrap/Divider/Divider";
import WidthWrapper from "../../components/ContentWrap/WidthWrapper";
import Filters, { FormData } from "../../components/ContentWrap/Filters";
import Products from "../../components/ContentWrap/Products";
import Footer from "../../components/Footer";
import ContentWrap from "../../components/ContentWrap";
import Loader from "../../components/ContentWrap/Loader";

//Api response type

type IVans = IVan[];

interface IVan {
  id: number;
  name: string;
  price: number;
  description: string;
  imageUrl: string;
  type: string;
  visible: boolean;
}

const Penises = () => {
  const [loading, setLoading] = useState(true);
  const [vans, setVans] = useState<IVans>();
  const [vansLoaded, setVansLoaded] = useState(false);
  useEffect(() => {
    try {
      fetch("/api/vans")
        .then((response) => response.json())
        .then((data) => {
          setVans(data.vans.map((van: IVan) => ({ ...van, visible: true })));
          setTimeout(() => {
            setLoading(false);
          }, 500);
          setVansLoaded(true);
        });
    } catch (error) {
      console.error(error);
      setVans(undefined);
      setLoading(false);
    }
  }, []);

  const filterData = useCallback(
    (childData: FormData) => {
      if (vansLoaded) {
        setVans((products) => {
          if (!products) {
            return products; //Here is error.
          } else {
            //Check if No filter is chosen
            const objectVals = Object.keys(childData).map((c) => childData[c]);
            const containsTrue = objectVals.find((c) => c);
            if (!containsTrue) {
              return products.map((product) => ({ ...product, visible: true }));
            }
            //If not, do filtering
            return products.map((c) => {
              //Filter by type
              const productType = c.type;

              //Check if its filtered or not. Marks missing as false
              const isTypeOn = childData[productType] || false;

              const toReturn = {
                ...c,
                visible: isTypeOn,
              };
              return toReturn;
            });
          }
        });
      } else if (!vansLoaded) {
        setTimeout(() => {
          setVansLoaded((p) => p);
        }, 200);
      }
    },
    [vansLoaded]
  );

  return (
    <>
      <ContentWrap>
        <WidthWrapper>
          <Divider height="60px" />
          <Heading>Example of our Van options</Heading>
          <Divider />
          <Filters onFormChange={filterData}>
            <Filters.Option id="simple">simple</Filters.Option>
            <Filters.Option id="rugged">rugged</Filters.Option>
            <Filters.Option id="luxury">luxury</Filters.Option>
            <Filters.Clear>Clear</Filters.Clear>
          </Filters>
          <Divider />
          <Products>
            {!loading ? (
              vans ? (
                vans.map((van) =>
                  van.visible ? (
                    <Products.Product link={`${van.id}`} key={van.id}>
                      <Products.Product.Image source={van.imageUrl} />
                      <Products.Product.Info>
                        <Products.Product.Info.Basic>
                          <Products.Product.Info.Basic.Name>
                            {van.name}
                          </Products.Product.Info.Basic.Name>
                          <Products.Product.Info.Basic.Tag>
                            {van.type}
                          </Products.Product.Info.Basic.Tag>
                        </Products.Product.Info.Basic>

                        <Products.Product.Info.Price>
                          <Products.Product.Info.Price.Value>
                            {van.price}
                          </Products.Product.Info.Price.Value>
                          <Products.Product.Info.Price.TimePer newLine={true}>
                            Day
                          </Products.Product.Info.Price.TimePer>
                        </Products.Product.Info.Price>
                      </Products.Product.Info>
                    </Products.Product>
                  ) : (
                    ""
                  )
                )
              ) : (
                "not yet product"
              )
            ) : (
              <Loader top="100" />
            )}
          </Products>
        </WidthWrapper>
      </ContentWrap>
      <Footer>Dobry den fotir &#169;</Footer>
    </>
  );
};

export default Penises;
