import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

interface IButtonProps {
  children: React.ReactNode;
  color?: string;
  bgColor?: string;
}

const BUTTON = styled.button<{ $color: string; $bgColor: string }>`
  color: ${(props) => props.$color};
  background-color: ${(props) => props.$bgColor};
  width: max-content;
  padding: 20px 10%;
  border-radius: 12px;
  cursor: pointer;
  border: none;
  transition: 0.2s ease-out;
  font-weight: bold;
  letter-spacing: 0.05em;
  &:hover {
    filter: invert(100%);
    transition: 0.2s;
  }
`;

const LinkWrap = styled(Link)`
  display: block;
  margin: auto;
`;

const Button = ({
  children,
  color = "white",
  bgColor = "#592ed3",
}: IButtonProps) => {
  return (
    <LinkWrap to="/about">
      <BUTTON $color={color} $bgColor={bgColor}>
        {children}
      </BUTTON>
    </LinkWrap>
  );
};

export default Button;
