import React from "react";
import { useContext } from "react";

import { HostVansDetailContext } from "../VansDetail";

const Details = () => {
  const context = useContext(HostVansDetailContext);
  console.log(context);

  return <p>{context?.description}</p>;
};

export default Details;
