import React from "react";
import styled from "styled-components";

interface ITextProps {
  children: React.ReactNode;
}

const TextContainer = styled.span`
  display: block;
`;

const Text = ({ children }: ITextProps) => {
  return <TextContainer>{children}</TextContainer>;
};

export default Text;
