import React, { ComponentPropsWithoutRef, createContext, Context } from "react";
import styled from "styled-components";

//Child component
import Text from "./Text";

//Fallback img
import fallbackImg from "./img/fallback.jpg";

interface IHeadingPageImageProps extends ComponentPropsWithoutRef<"div"> {
  bgImg?: string;
  bgPosition?: string;
  bgHeight?: number;
  children?: React.ReactNode;
  brightness?: number;
}

const Background = styled.div<{
  $bgImg: string;
  $bgPosition: string;
  $bgHeight: number;
}>`
  height: ${(props) => props.$bgHeight + "px"};
  background-image: url(${(props) => props.$bgImg});
  background-repeat: no-repeat;
  background-position: ${(props) => props.$bgPosition};
  background-size: cover;
`;

//Create context
interface HeadingPageContextType {
  brightness: number;
}
export const HeadingPageImageContext: Context<HeadingPageContextType> =
  createContext({
    brightness: 65,
  });

const HeadingPageImage = ({
  bgImg = fallbackImg,
  bgPosition = "center",
  bgHeight = 250,
  brightness = 65,
  children,
  ...rest
}: IHeadingPageImageProps) => {
  return (
    <HeadingPageImageContext.Provider value={{ brightness }}>
      <Background
        $bgImg={bgImg}
        $bgPosition={bgPosition}
        $bgHeight={bgHeight}
        {...rest}
      >
        {children}
      </Background>
    </HeadingPageImageContext.Provider>
  );
};

HeadingPageImage.Text = Text;

export default HeadingPageImage;
