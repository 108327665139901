import React, { useEffect, useState, createContext } from "react";
import { useParams, Outlet, Link } from "react-router-dom";
import BasicLink from "../../helpers/custom-components/BasicNavLink";
import HostProductDetail from "../../../components/ContentWrap/_host/HostProductDetail";
import { ListItem } from "../../../components/ContentWrap/_host/HostMenu";
import Divider from "../../../components/ContentWrap/Divider/Divider";
import Loader from "../../../components/ContentWrap/Loader";
import ContentWrap from "../../../components/ContentWrap";

export const HostVansDetailContext = createContext<null | IProductData>(null);
//Type of API res
interface IProductData {
  id: number;
  price: number;
  name: string;
  description: string;
  imageUrl: string;
  type: string;
}

const VansDetail = () => {
  const { id } = useParams();
  const [productData, setProductData] = useState<IProductData | null>(null);
  useEffect(() => {
    try {
      fetch(`/api/host/vans/${id}`)
        .then((response) => response.json())
        .then((data) => {
          console.log(data.vans);
          if (data.vans.length > 1) {
            throw new Error("received more than 1 van");
          } else {
            setProductData(data.vans[0]);
          }
        });
    } catch (error) {
      console.error(error);
    }
  }, [id]);
  return (
    <HostVansDetailContext.Provider value={productData}>
      <ContentWrap>
        <Link to=".." style={{ color: "inherit" }}>
          ← Back to all vans
        </Link>
        <Divider height="3rem" />
        <h1>VansDetail {id}</h1>
        <Divider />
        {productData ? (
          <HostProductDetail>
            <HostProductDetail.Image source={productData.imageUrl} />
            <HostProductDetail.TextContent>
              <HostProductDetail.TextContent.HostTag>
                {productData.type}
              </HostProductDetail.TextContent.HostTag>
              <HostProductDetail.TextContent.Name>
                {productData.name}
              </HostProductDetail.TextContent.Name>
              <HostProductDetail.TextContent.Price>
                {productData.price} / Day
              </HostProductDetail.TextContent.Price>
            </HostProductDetail.TextContent>
          </HostProductDetail>
        ) : (
          <Loader />
        )}

        <Divider />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <nav>
            <ul style={{ listStyleType: "none" }}>
              <ListItem>
                <BasicLink to="." end>
                  Details
                </BasicLink>
              </ListItem>
              <ListItem>
                <BasicLink to="pricing">Pricing</BasicLink>
              </ListItem>
              <ListItem>
                <BasicLink to="photos">Photos</BasicLink>
              </ListItem>
            </ul>
          </nav>
          <button>EDIT</button>
        </div>
        <Divider />
        <Outlet />
        <Divider height="150px" />
      </ContentWrap>
    </HostVansDetailContext.Provider>
  );
};

export default VansDetail;
