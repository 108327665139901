import React from "react";
import styled from "styled-components";

//Subcomponents
import Price from "./Price";
import Basic from "./Basic/idex";

interface IInfoProps {
  children: React.ReactNode;
}

const ProductInfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0.7rem;
`;

const Info = ({ children }: IInfoProps) => {
  return <ProductInfoRow>{children}</ProductInfoRow>;
};

Info.Basic = Basic;
Info.Price = Price;

export default Info;
