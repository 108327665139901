import React from "react";
import styled from "styled-components";

//Style
const PricePer = styled.span<{ $newLine: boolean }>`
  display: ${({ $newLine }) => ($newLine ? "block" : "inline")};
`;

interface ITimePerProps {
  children: React.ReactNode;
  newLine?: boolean;
}

const TimePer = ({ children, newLine = false }: ITimePerProps) => {
  return <PricePer $newLine={newLine}>/{children}</PricePer>;
};

export default TimePer;
