import React, { useContext, useEffect, useRef } from "react";

//Component logic
import useOption from "./useOption";

//Context
import { FiltersContext } from "..";

//Styles
import { Checkbox, Label, Text } from "./styles";

//TYPE - Component props
export interface IOptionProps {
  children: React.ReactNode;
  id: string;
}

const Option = ({ children, id }: IOptionProps) => {
  const context = useContext(FiltersContext);
  const alreadyRun = useRef(false);

  useEffect(() => {
    alreadyRun.current = false;
  }, [context?.searchParams]);

  useEffect(() => {
    //Make an object out of type params
    const params = context?.searchParams.getAll("type");
    let paramsObj: Record<string, boolean> = {};
    if (params) {
      params.forEach((param) => {
        paramsObj[param] = true;
      });
    }

    //Set form data
    if (context && !alreadyRun.current) {
      alreadyRun.current = true;
      context.setFormData((p) => {
        const prevCopy = { ...p };
        Object.keys(prevCopy).forEach((key) => {
          prevCopy[key] = false;
        });

        return {
          ...prevCopy,
          ...paramsObj,
        };
      });
    }
  }, [context]);

  const isChecked: boolean = useOption(context, id);

  //Event handler for checkbox change

  //Refactor to change only search params
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, checked } = e.target;

    //Old params
    const oldParams = context?.searchParams.getAll("type") || [];

    //New params
    const newParams = new URLSearchParams();

    if (checked) {
      newParams.set("type", name);
      oldParams.forEach((c) => {
        newParams.append("type", c);
      });
      context?.setSearchParams(newParams);
    } else if (!checked) {
      const filtered = oldParams.filter((param) => {
        return param !== name;
      });
      filtered.forEach((c) => {
        newParams.append("type", c);
      });

      context?.setSearchParams(newParams);
    }
  };

  return (
    <Label>
      <Checkbox name={id} checked={isChecked} onChange={handleCheckboxChange} />
      <Text>{children}</Text>
    </Label>
  );
};

export default Option;

//TO do - refactor so single source of truth is params
//Onchange event should
//Update params only.

//create useEffect so when params change, update formData from useParams (get("type"))
//formData will remain the source of truth for controlled components
