import React from "react";
import { Grid } from "react-loader-spinner";
import styled from "styled-components";

const CustomLoader = styled(Grid).attrs<{
  $color: string;
  $size: string;
  $top: string;
  $margin: string;
}>((props) => ({
  height: props.$size,
  width: props.$size,
  color: props.$color,
  ariaLabel: "grid-loading",
  radius: "12.5",
  wrapperStyle: {
    margin: props.$margin,
    width: "fit-content",
    paddingTop: `${props.$top}px`,
  },
}))``;

interface ILoaderProps {
  color?: string;
  size?: string;
  top?: string;
  margin?: string;
}

const Loader = ({
  color = "#ffd095",
  size = "120",
  top = "200",
  margin = "0 auto",
  ...rest
}: ILoaderProps) => {
  return (
    <CustomLoader
      $color={color}
      $size={size}
      $top={top}
      $margin={margin}
      {...rest}
    />
  );
};

export default Loader;
