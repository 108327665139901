import React from "react";
import styled from "styled-components";

//Subcomponents
import ActionButton from "./ActionButton";
import Description from "./Description";
import Name from "./Name";
import Price from "./Price";
import Tag from "./Tag";

const InfoWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

interface IInfoProps {
  children: React.ReactNode;
}
const Info = ({ children }: IInfoProps) => {
  return <InfoWrap>{children}</InfoWrap>;
};

Info.ActionButton = ActionButton;
Info.Description = Description;
Info.Name = Name;
Info.Price = Price;
Info.Tag = Tag;

export default Info;
