import React from "react";
import styled from "styled-components";

//Styled
const PriceText = styled.span`
  display: block;
`;

interface IPriceProps {
  children: React.ReactNode;
}

const Price = ({ children }: IPriceProps) => {
  return <PriceText>{children}</PriceText>;
};

export default Price;
