import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Divider from "../../../Divider/Divider";

//Subcomponents
import Image from "./Image";
import TextContent from "./TextContent";

//Styled
const ProductItem = styled.li`
  height: 120px;
  background-color: #f7eed5;
  padding: 10px;
  border-radius: 5px;
  border: 2px solid #d2d2d2;
`;

interface IItemProps {
  children: React.ReactNode;
  id: string;
}

const Item = ({ children, id }: IItemProps) => {
  return (
    <>
      <Link to={id} style={{ color: "inherit" }}>
        <ProductItem>{children}</ProductItem>
      </Link>
      <Divider />
    </>
  );
};

Item.Image = Image;
Item.TextContent = TextContent;
export default Item;
