import React from "react";
import { Outlet, useLocation } from "react-router-dom";

//import subcomponents
import Menu from "../../../components/ContentWrap/Menu";
import Footer from "../../../components/Footer";

const MenuAndFooter = () => {
  const { pathname } = useLocation();

  return (
    <>
      <Menu active={pathname} />
      <Outlet />
      <Footer>Dobry den</Footer>
    </>
  );
};

export default MenuAndFooter;
