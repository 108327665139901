import React from "react";
import { Outlet } from "react-router-dom";

//pages
import HostMenu from "../../../components/ContentWrap/_host/HostMenu";

//components
import WidthWrapper from "../../../components/ContentWrap/WidthWrapper";
import Divider from "../../../components/ContentWrap/Divider/Divider";

const HostNav = () => {
  return (
    <>
      <Divider height="2rem" />
      <WidthWrapper>
        <HostMenu />
        <Divider height="3.5rem" />
        <Outlet />
      </WidthWrapper>
    </>
  );
};

export default HostNav;
