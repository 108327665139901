import React, { ComponentPropsWithoutRef } from "react";
import styled from "styled-components";

interface IHeadingProps extends ComponentPropsWithoutRef<"h2"> {
  children: React.ReactNode;
}

const H2 = styled.h2`
  margin-bottom: 1rem;
`;

const Heading = ({ children, ...rest }: IHeadingProps) => {
  return <H2 {...rest}>{children}</H2>;
};

export default Heading;
