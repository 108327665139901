import React from "react";
import styled from "styled-components";
import Divider from "../../../../Divider/Divider";

//Styles
const Paragraph = styled.p``;

interface IDescriptionProps {
  children: React.ReactNode;
}
const Description = ({ children }: IDescriptionProps) => {
  return (
    <>
      <Paragraph>{children}</Paragraph>
      <Divider style={{ flex: "1", minHeight: "2rem" }} />
    </>
  );
};

export default Description;
