import React from "react";
import { Link } from "react-router-dom";

//Components
import Footer from "../../components/Footer";
import ContentWrap from "../../components/ContentWrap";
import HeadingPageImage from "../../components/ContentWrap/HeadingPageImage";
import TextSection from "../../components/ContentWrap/TextSection";
import Divider from "../../components/ContentWrap/Divider/Divider";
import TextBannerClick from "../../components/ContentWrap/TextBannerClick";
import WidthWrapper from "../../components/ContentWrap/WidthWrapper";

const About = () => {
  return (
    <>
      <ContentWrap>
        <HeadingPageImage brightness={60}>
          <HeadingPageImage.Text color="white">About us</HeadingPageImage.Text>
        </HeadingPageImage>
        <Divider height="2rem" />
        <WidthWrapper>
          <TextSection>
            <TextSection.Heading>Text section Heading</TextSection.Heading>
            <TextSection.Text>
              <p>
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                Excepteur sint occaecat cupidatat non proident, sunt in culpa
                qui officia deserunt mollit anim id est laborum. Etiam dui sem,
                fermentum vitae, sagittis id, malesuada in, quam.
              </p>
              <br />
              <p>
                Nam quis nulla. Aenean id metus id velit ullamcorper pulvinar.
                Maecenas ipsum velit, consectetuer eu lobortis ut, dictum at
                dui. Aenean fermentum risus id tortor. Maecenas libero. Maecenas
                ipsum velit, consectetuer eu lobortis ut, dictum at dui. Sed vel
                lectus. Donec odio tempus molestie, porttitor ut, iaculis quis,
                sem.
              </p>
            </TextSection.Text>
          </TextSection>
          <Divider height="3rem" />
          <TextBannerClick bgColor="#c9d3ea" width="50%" mobileWidth="90%">
            <TextBannerClick.Heading>
              Want to see more? Explore our unique penis collections! It's so
              simple, just click the button below!
            </TextBannerClick.Heading>

            <Link
              to="/penises"
              style={{
                textDecoration: "none",
                display: "block",
                width: "max-content",
                marginLeft: "100%",
                transform: "translateX(-100%)",
              }}
            >
              <TextBannerClick.Button>See penises</TextBannerClick.Button>
            </Link>
          </TextBannerClick>
        </WidthWrapper>
      </ContentWrap>
      <Divider height="3rem" />
      <Footer>Dobry den fotir &#169;</Footer>
    </>
  );
};

export default About;
