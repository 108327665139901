import React, { ComponentPropsWithoutRef } from "react";
import styled from "styled-components";

//Children components
import Heading from "./Heading";
import Button from "./Button";

const BannerContainer = styled.div<{
  $color: string;
  $bgColor: string;
  $width: string;
  $mobileWidth: string;
}>`
  padding: 20px;
  background-color: ${({ $bgColor }) => $bgColor};
  color: ${({ $color }) => $color};
  border-radius: 5px;
  width: ${({ $width }) => $width};
  @media (max-width: 585px) {
    width: ${({ $mobileWidth }) => $mobileWidth};
  }
`;

interface ITextBannerClickProps extends ComponentPropsWithoutRef<"div"> {
  children: React.ReactNode;
  color?: string;
  bgColor?: string;
  width?: string;
  mobileWidth?: string;
}

const TextBannerClick = ({
  children,
  color = "black",
  bgColor = "inherit",
  width = "auto",
  mobileWidth = "90%",
  ...rest
}: ITextBannerClickProps) => {
  return (
    <BannerContainer
      $color={color}
      $bgColor={bgColor}
      $width={width}
      $mobileWidth={mobileWidth}
      {...rest}
    >
      {children}
    </BannerContainer>
  );
};

TextBannerClick.Heading = Heading;
TextBannerClick.Button = Button;

export default TextBannerClick;
