import React from "react";
import {
  default as ListingPrice,
  IPriceProps,
} from "../../../../Products/Product/Info/Price";
import Divider from "../../../../Divider/Divider";

const Price = ({ children }: IPriceProps) => {
  return (
    <>
      <ListingPrice>{children}</ListingPrice>
      <Divider />
    </>
  );
};

Price.Value = ListingPrice.Value;
Price.TimePer = ListingPrice.TimePer;

export default Price;
