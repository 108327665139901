import React, { createContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";

//Subcomponents
import Product from "./Product";

interface IProductsProps {
  children: React.ReactNode;
}

export const ProductsContext = createContext(700);

const ProductsWrap = styled.main<{ $width: number }>`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
`;

const Products = ({ children }: IProductsProps) => {
  const [width, setWidth] = useState(700);

  //Effect
  useEffect(() => {
    if (mainRef.current) {
      setWidth(mainRef.current.clientWidth);
    }

    const handleResize = () => {
      if (mainRef.current) {
        setWidth(mainRef.current.clientWidth);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const mainRef = useRef<HTMLElement>(null);

  //Return
  return (
    <ProductsContext.Provider value={width}>
      <ProductsWrap $width={width} ref={mainRef}>
        {children}
      </ProductsWrap>
    </ProductsContext.Provider>
  );
};

Products.Product = Product;

export default Products;
