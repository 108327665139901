import React, { useState, createContext, useEffect } from "react";
import { SetURLSearchParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";

//Subcomponents
import Clear from "./Clear";
import Option from "./Option";

//Types to export
export type FormData = Record<string, boolean>;

//Context
export interface IContext {
  formData: Record<string, boolean>;
  setFormData: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
  reset: boolean;
  setReset: React.Dispatch<React.SetStateAction<boolean>>;
  searchParams: URLSearchParams;
  setSearchParams: SetURLSearchParams;
}
export const FiltersContext = createContext<IContext | null>(null);

//Component
interface IFiltersProps {
  children: React.ReactNode;
  onFormChange: (arg: FormData) => void;
}

const FilterWrap = styled.form``;

const Filters = ({ children, onFormChange }: IFiltersProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  //To do - default should be taken from searchParams
  const [formData, setFormData] = useState({});

  //init reset bool. Reset button changes state.
  const [reset, setReset] = useState(false);

  //Callback from upper component. Gives the upper component formData
  useEffect(() => {
    onFormChange(formData);
  }, [formData, onFormChange]);

  //Return
  return (
    <FiltersContext.Provider
      value={{
        formData,
        setFormData,
        setReset,
        reset,
        searchParams,
        setSearchParams,
      }}
    >
      <FilterWrap>{children}</FilterWrap>
    </FiltersContext.Provider>
  );
};

Filters.Option = Option;
Filters.Clear = Clear;

export default Filters;
