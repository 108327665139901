import React, { useEffect, useState } from "react";

//Components
import Heading from "../../../components/ContentWrap/TextSection/Heading";
import HostProductsList from "../../../components/ContentWrap/_host/HostProductsList";
import ContentWrap from "../../../components/ContentWrap";
import Loader from "../../../components/ContentWrap/Loader";

//Type
interface IapiResponse {
  vans: Ivan[];
}

interface Ivan {
  description: string;
  hostId: string;
  id: string;
  imageUrl: string;
  name: string;
  price: number;
  type: string;
}

const Vans = () => {
  const [hostVans, setHostVans] = useState<Ivan[]>();

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await fetch("/api/host/vans");
        const data: IapiResponse = await response.json();
        setTimeout(() => {
          setHostVans(data.vans);
        }, 500);
      } catch (error) {
        console.error(error);
      }
    };
    getData();
  }, []);
  return (
    <ContentWrap>
      <Heading>Your Listed Vans</Heading>
      <HostProductsList>
        {hostVans ? (
          hostVans.map((van) => (
            <HostProductsList.Item id={van.id}>
              <HostProductsList.Item.Image source={van.imageUrl} />
              <HostProductsList.Item.TextContent>
                <HostProductsList.Item.TextContent.Name>
                  {van.name}
                </HostProductsList.Item.TextContent.Name>
                <HostProductsList.Item.TextContent.Price>
                  {van.price} / Day
                </HostProductsList.Item.TextContent.Price>
              </HostProductsList.Item.TextContent>
            </HostProductsList.Item>
          ))
        ) : (
          <Loader top="30" margin="0 0 50px 40px" />
        )}
      </HostProductsList>
    </ContentWrap>
  );
};

export default Vans;
