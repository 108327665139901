import React from "react";
import styled from "styled-components";

interface ISecondHeadingProps {
  children: React.ReactNode;
}

const H2 = styled.h2`
  color: white;
  font-size: medium;
  margin-bottom: 2rem;
`;

const SecondHeading = ({ children }: ISecondHeadingProps) => {
  return <H2>{children}</H2>;
};

export default SecondHeading;
