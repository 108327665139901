import React from "react";
import styled from "styled-components";

//Styled
const NameText = styled.b`
  display: block;
  padding: 6px 0;
`;

interface INameProps {
  children: React.ReactNode;
}

const Name = ({ children }: INameProps) => {
  return <NameText>{children}</NameText>;
};

export default Name;
