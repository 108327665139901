import styled from "styled-components";

export const Checkbox = styled.input.attrs({ type: "checkbox" })`
  &:checked + span {
    border: 3px solid black;
  }
  display: none;
`;

export const Label = styled.label`
  display: inline-block;
  margin: 0 1rem;
  &:first-of-type {
    margin: 0;
  }
  &:last-of-type {
    margin: 0;
  }
`;

export const Text = styled.span`
  padding: 0.6rem;
  display: inherit;
  background-color: #fff3dc;
  border: 2px solid transparent;
  border-radius: 0.3rem;
  cursor: pointer;
  box-shadow: 3px 3px 0 black;
  font-weight: bolder;
  letter-spacing: 0.05em;
`;
