import React from "react";
import styled from "styled-components";

interface IDividerProps extends React.ComponentPropsWithoutRef<"div"> {
  height?: string;
}

const DividerElement = styled.div<{ $height: string }>`
  height: ${({ $height }) => $height};
  background: none;
`;

const Divider = ({ height = "1rem", ...rest }: IDividerProps) => {
  return <DividerElement $height={height} {...rest} />;
};

export default Divider;
