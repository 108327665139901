import React, { useContext } from "react";
import styled from "styled-components";

//Import context
import { ProductDetailContext } from "../../..";

//Styles
const Button = styled.button<{ $width: number }>`
  padding: 30px 50px;
  background-color: orange;
  color: white;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  width: "max-content";
  margin: ${({ $width }) => ($width < 900 ? "auto" : "0 0 0 auto")};
  box-shadow: 3px 3px 0 black;
`;

interface IActionButtonProps {
  children: React.ReactNode;
}
const ActionButton = ({ children }: IActionButtonProps) => {
  const { width } = useContext(ProductDetailContext);
  return <Button $width={width}>{children}</Button>;
};

export default ActionButton;
