import React from "react";
import styled from "styled-components";

//Import Styles
import BasicLink from "../../../../router/helpers/custom-components/BasicNavLink";

//Local Styles
const List = styled.ul`
  list-style-type: none;
`;

export const ListItem = styled.li`
  display: inline-block;
  margin-right: 2rem;
  &:last-of-type {
    margin-right: 0;
  }
`;

const HostMenu = () => {
  return (
    <nav>
      <List>
        <ListItem>
          <BasicLink to="/host" end>
            Dashboard
          </BasicLink>
        </ListItem>
        <ListItem>
          <BasicLink to="income">Income</BasicLink>
        </ListItem>
        <ListItem>
          <BasicLink to="vans">Vans</BasicLink>
        </ListItem>
        <ListItem>
          <BasicLink to="reviews">Reviews</BasicLink>
        </ListItem>
      </List>
    </nav>
  );
};

export default HostMenu;
