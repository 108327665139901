import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

//Components
import Loader from "../../components/ContentWrap/Loader";
import ContentWrap from "../../components/ContentWrap";
import Footer from "../../components/Footer";
import ProductDetail from "../../components/ContentWrap/ProductDetail";
import WidthWrapper from "../../components/ContentWrap/WidthWrapper";
import Divider from "../../components/ContentWrap/Divider/Divider";

import "../../MockServer";

//Typing data

interface IProductData {
  id: number;
  price: number;
  name: string;
  description: string;
  imageUrl: string;
  type: string;
}

const Detail = () => {
  const { id } = useParams();
  const [productData, setProductData] = useState<IProductData | null>(null);
  useEffect(() => {
    try {
      fetch(`/api/vans/${id}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.length > 1) {
            throw new Error("received more than 1 van");
          } else {
            setProductData(data.vans);
          }
        });
    } catch (error) {
      console.error(error);
    }
  }, [id]);

  return (
    <>
      <ContentWrap>
        <Divider height="50px" />
        <WidthWrapper>
          {productData ? (
            <ProductDetail>
              <ProductDetail.GoBack>Get back to overview</ProductDetail.GoBack>
              <ProductDetail.Main>
                <ProductDetail.Main.Image source={productData.imageUrl} />
                <ProductDetail.Main.Info>
                  <ProductDetail.Main.Info.Tag>
                    {productData.type}
                  </ProductDetail.Main.Info.Tag>
                  <ProductDetail.Main.Info.Name>
                    {productData.name}
                  </ProductDetail.Main.Info.Name>
                  <ProductDetail.Main.Info.Price>
                    <ProductDetail.Main.Info.Price.Value>
                      {productData.price}
                    </ProductDetail.Main.Info.Price.Value>
                    <ProductDetail.Main.Info.Price.TimePer>
                      Day
                    </ProductDetail.Main.Info.Price.TimePer>
                  </ProductDetail.Main.Info.Price>
                  <ProductDetail.Main.Info.Description>
                    {productData.description}
                  </ProductDetail.Main.Info.Description>
                  <ProductDetail.Main.Info.ActionButton>
                    Rent this penis
                  </ProductDetail.Main.Info.ActionButton>
                </ProductDetail.Main.Info>
              </ProductDetail.Main>
            </ProductDetail>
          ) : (
            <Loader />
          )}
        </WidthWrapper>
        <Divider height="200px" />
      </ContentWrap>
      <Footer>Dobry den</Footer>
    </>
  );
};

export default Detail;
