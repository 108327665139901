import React from "react";
import styled from "styled-components";

//Child component
import Item from "./Item";

//Styled
const ListWrap = styled.ul`
  list-style-type: none;
`;

const Main = styled.main`
  @media (min-width: 1024px) {
    width: 50%;
  }
`;

interface IHostProductsListProps {
  children: React.ReactNode;
}
const HostProductsList = ({ children }: IHostProductsListProps) => {
  return (
    <Main>
      <ListWrap>{children}</ListWrap>;
    </Main>
  );
};

HostProductsList.Item = Item;

export default HostProductsList;
