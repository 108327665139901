import React from "react";
import styled from "styled-components";

const Wrapper = styled.div<{
  $desktop: number;
  $tablet: number;
  $mobile: number;
}>`
  margin: auto;
  width: ${({ $desktop }) => $desktop}%;

  @media (max-width: 1024px) {
    width: ${({ $tablet }) => $tablet}%;
  }

  @media (max-width: 425px) {
    width: ${({ $mobile }) => $mobile}%;
  }
`;

interface IWidthWrapperProps {
  children: React.ReactNode;
  widthData?: {
    mobileWidth: number;
    desktopWidth: number;
    tabletWidth: number;
  };
}

const WidthWrapper = ({
  children,
  widthData = {
    mobileWidth: 90,
    desktopWidth: 70,
    tabletWidth: 80,
  },
}: IWidthWrapperProps) => {
  return (
    <Wrapper
      $desktop={widthData.desktopWidth}
      $tablet={widthData.tabletWidth}
      $mobile={widthData.mobileWidth}
    >
      {children}
    </Wrapper>
  );
};

export default WidthWrapper;
