import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

//img
import arrImg from "./img/arr-left.png";
import Divider from "../../Divider/Divider";

//Styles
const BackRef = styled(Link)`
  color: black;
  font-size: x-large;
  display: block;
  &::before {
    background-image: url(${arrImg});
    content: "";
    background-size: cover;
    background-position: center;
    width: 1.2em;
    height: 0.65em;
    margin-right: 0.3em;
    display: inline-block;
    background-repeat: no-repeat;
  }
`;

interface IGoBackProps {
  children: React.ReactNode;
  to?: string;
}
const GoBack = ({ children, to = "/penises" }: IGoBackProps) => {
  return (
    <>
      <BackRef to={to}>{children}</BackRef>
      <Divider height="2rem" />
    </>
  );
};

export default GoBack;
