import React, { ComponentPropsWithoutRef } from "react";
import styled from "styled-components";

const CustomButton = styled.button<{ $color: string; $bgColor: string }>`
  border: 1px solid transparent;
  cursor: pointer;
  padding: 1rem;
  color: ${({ $color }) => $color};
  background-color: ${({ $bgColor }) => $bgColor};
  transition: 0.3s ease-in;
  & > * {
    color: ${({ $color }) => $color};
  }
  &:hover {
    background-color: ${({ $bgColor }) => $bgColor};
    filter: invert(100%);
    transition: 0.3s ease;
    border: 1px solid ${({ $color }) => $color};
  }
`;

interface IButtonProps extends ComponentPropsWithoutRef<"button"> {
  children: React.ReactNode;
  color?: string;
  bgColor?: string;
}

const Button = ({
  children,
  color = "white",
  bgColor = "black",
  ...rest
}: IButtonProps) => {
  return (
    <CustomButton $color={color} $bgColor={bgColor} {...rest}>
      {children}
    </CustomButton>
  );
};

export default Button;
