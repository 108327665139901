import React from "react";
import styled from "styled-components";

//Subcomponents
import Name from "./Name";
import Price from "./Price";
import HostTag from "./HostTag";

//Styled
const Text = styled.div`
  display: inline-block;
  vertical-align: middle;
  padding-left: 40px;
  @media (max-width: 768px) {
    padding-top: 20px;
    padding-left: 0;
    display: block;
  }
`;

interface ITextContentProps {
  children: React.ReactNode;
}

const TextContent = ({ children }: ITextContentProps) => {
  return <Text>{children}</Text>;
};

TextContent.Name = Name;
TextContent.Price = Price;
TextContent.HostTag = HostTag;

export default TextContent;
