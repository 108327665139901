import React from "react";
import styled from "styled-components";

//Subcomponents
import Name from "./Name";
import Price from "./Price";

//Styled
const Text = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

interface ITextContentProps {
  children: React.ReactNode;
}

const TextContent = ({ children }: ITextContentProps) => {
  return <Text>{children}</Text>;
};

TextContent.Name = Name;
TextContent.Price = Price;

export default TextContent;
