import React, { ComponentPropsWithoutRef } from "react";
import styled from "styled-components";

interface IHeadingProps extends ComponentPropsWithoutRef<"h3"> {
  children: React.ReactNode;
  color?: string;
  bgColor?: string;
}

const H3 = styled.h3<{ $color: string; $bgColor: string }>`
  color: ${({ $color }) => $color};
  background-color: ${({ $bgColor }) => $bgColor};
  margin-bottom: 1rem;
`;

const Heading = ({
  children,
  color = "black",
  bgColor = "inherit",
  ...rest
}: IHeadingProps) => {
  return (
    <H3 $color={color} $bgColor={bgColor} {...rest}>
      {children}
    </H3>
  );
};

export default Heading;
