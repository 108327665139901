import React from "react";
import styled from "styled-components";

interface IValueProps {
  children: React.ReactNode;
}

const Price = styled.span`
  display: inline;
  font-weight: bold;
  font-size: 1.2rem;
`;

const Value = ({ children }: IValueProps) => {
  return <Price>${children}</Price>;
};

export default Value;
