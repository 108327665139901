import React, { useContext } from "react";
import styled from "styled-components";

//Import context
import { HeadingPageImageContext } from "../index";

interface ITextProps {
  children: React.ReactNode;
  color?: string;
}

const H1 = styled.h1<{ $color: string; $brightness: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
  backdrop-filter: brightness(${({ $brightness }) => $brightness}%);
  color: ${({ $color }) => $color};
`;

const Text = ({ children, color = "auto" }: ITextProps) => {
  const { brightness } = useContext(HeadingPageImageContext);
  return (
    <H1 $color={color} $brightness={brightness}>
      {children}
    </H1>
  );
};

export default Text;
