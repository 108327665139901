import React from "react";
import styled from "styled-components";

//img
import fallbackImg from "./img/fallback-image.jpg";

//Children components
import Button from "./Button";
import Heading from "./Heading";
import SecondHeading from "./SecondHeading";

//Props types
interface IMainpageHeroProps {
  children: React.ReactNode;
  headerHeight?: number; //Header height in pixels
  bgImg?: string;
  bgPosition?: string;
  brightness?: number;
}

const Background = styled.main<{
  $headerHeight: number;
  $bgImg: string;
  $bgPosition: string;
}>`
  background-image: url(${(props) => props.$bgImg});
  background-repeat: no-repeat;
  background-position: ${(props) => props.$bgPosition};
  background-size: cover;
  height: calc(100dvh - ${(props) => props.$headerHeight + "px"});
  overflow-y: auto;
  @media (max-height: 430px) {
    height: auto;
  }
`;

const Content = styled.div<{ $brightness: number }>`
  backdrop-filter: brightness(${(props) => props.$brightness + "%"});
  height: inherit;
  padding: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const MainpageHero = ({
  children,
  headerHeight = 200,
  bgImg = fallbackImg,
  bgPosition = "center",
  brightness = 60,
}: IMainpageHeroProps) => {
  return (
    <Background
      $headerHeight={headerHeight}
      $bgImg={bgImg}
      $bgPosition={bgPosition}
    >
      <Content $brightness={brightness}>{children}</Content>
    </Background>
  );
};

MainpageHero.Button = Button;
MainpageHero.Heading = Heading;
MainpageHero.SecondHeading = SecondHeading;

export default MainpageHero;
