import React, { useContext } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

//Import context
import { ProductsContext } from "..";

//Subcomponents
import Image from "./Image";
import Info from "./Info";

const ProductWrap = styled.div<{ $width: number }>`
  width: ${({ $width }) => {
    if ($width > 1024) return "20%";
    if ($width > 768) return "27%";
    if ($width > 425) return "45%";
    if ($width > 320) return "100%";
  }};
  ${({ $width }) => {
    if ($width > 1024)
      return `
        margin-right:6.66%;
        &:nth-of-type(4n) {
          margin:0;
        }
      `;
    if ($width > 768)
      return `
      margin-right:9.5%;
      &:nth-of-type(3n) {
        margin:0;
      }
      `;
    if ($width > 425)
      return `
      margin-right:10%;
        &:nth-of-type(2n) {
          margin:0;
        }
    `;

    if ($width > 320)
      return `
      margin:0;
    `;
  }};

  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
`;

const LinkWrap = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

//Component
interface IProductProps {
  children: React.ReactNode;
  link: string;
}
const Product = ({ children, link }: IProductProps) => {
  const width = useContext(ProductsContext);

  return (
    <ProductWrap $width={width}>
      <LinkWrap to={link}>{children}</LinkWrap>
    </ProductWrap>
  );
};

Product.Image = Image;
Product.Info = Info;

export default Product;
