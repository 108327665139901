import React from "react";

//Components
import MainpageHero from "../../components/ContentWrap/MainpageHero";
import Footer from "../../components/Footer";
import ContentWrap from "../../components/ContentWrap";

const Mainpage = () => {
  return (
    <>
      <ContentWrap>
        {/* headerHeight can represent whatever value you want to deduct from 100vh */}
        <MainpageHero headerHeight={162} bgPosition="50% 30%">
          <MainpageHero.Heading>Check this out</MainpageHero.Heading>
          <MainpageHero.SecondHeading>
            Here you have some sample text to simulate a real usage. Our
            products are the worst on the market.
          </MainpageHero.SecondHeading>
          <MainpageHero.Button>Click me and browse</MainpageHero.Button>
        </MainpageHero>
      </ContentWrap>
      <Footer>Dobry den fotir &#169;</Footer>
    </>
  );
};

export default Mainpage;
