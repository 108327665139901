import React from "react";
import styled from "styled-components";

interface IHeadingProps {
  children: React.ReactNode;
}

const H1 = styled.h1`
  color: white;
  font-size: xxx-large;
  margin-bottom: 1rem;
`;

const Heading = ({ children }: IHeadingProps) => {
  return <H1>{children}</H1>;
};

export default Heading;
