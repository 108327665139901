import React from "react";
import Tag from "../../../../Products/Product/Info/Basic/Tag";

interface IHostTagProps {
  children: string;
}

const HostTag = ({ children }: IHostTagProps) => {
  return <Tag>{children}</Tag>;
};

export default HostTag;
