import { RefObject, useEffect, useState } from "react";

const useWatchWidth = (componentRef: RefObject<HTMLElement>) => {
  const wrapRef = componentRef;
  const [wrapWidth, setWrapWidth] = useState<number>(800);

  useEffect(() => {
    // On component render, set wrap width
    if (wrapRef.current) setWrapWidth(wrapRef.current?.clientWidth);

    //Handle resizing
    const handleResize = () => {
      if (wrapRef.current) setWrapWidth(wrapRef.current?.clientWidth);
    };
    window.addEventListener("resize", handleResize);

    //Clear event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [wrapRef]);

  return wrapWidth;
};

export default useWatchWidth;
