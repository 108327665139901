import React, { useContext } from "react";
import styled from "styled-components";

//Import context
import { ProductDetailContext } from "../..";

interface IImageProps {
  source: string;
}

const Img = styled.img<{ $width: number }>`
  width: ${({ $width }) => ($width < 900 ? "100%" : "auto")};
  max-height: 400px;
  object-fit: contain;
`;

const Image = ({ source }: IImageProps) => {
  const { width } = useContext(ProductDetailContext);
  return <Img src={source} alt="van" $width={width} />;
};

export default Image;
