import React from "react";
import styled from "styled-components";

//Styled
const ProductTag = styled.span<{ $bgColor: string }>`
  background-color: ${({ $bgColor }) => $bgColor};
  display: inline-block;
  color: white;
  padding: 0.7rem;
  border-radius: 0.3rem;
`;

export interface ITagProps extends React.ComponentPropsWithoutRef<"span"> {
  children: string;
}

const Tag = ({ children, ...rest }: ITagProps) => {
  const tagBgColor = () => {
    if (children.includes("rugged")) {
      return "#E17654";
    } else if (children.includes("simple")) {
      return "#115E59";
    } else if (children.includes("luxury")) {
      return "#161616";
    } else {
      return "#bc851c"; //Fallback
    }
  };

  return (
    <ProductTag $bgColor={tagBgColor()} {...rest}>
      {children}
    </ProductTag>
  );
};

export default Tag;
